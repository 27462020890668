<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :showExport="true" :searchs="searchs" @search="onSearch" @reset="reset" @export="onExport"></search>
        <div class="mt_10 relative width_100_percent flex space_between">
            <div>仅退款{{ applyModeTwo.nums || 0 }}单，退货退款{{ applyModeOne.nums || 0 }}单，仅退款金额{{ applyModeTwo.moneyS | cny }}元，退货退款{{ applyModeOne.moneyS | cny }}元</div>
            <!-- <Button type="primary" ghost @click="onRefundAnalysis">退款分析</Button> -->
        </div>
        <Card class="mt_10 table_container" ref="table_container">
            <div class="width_100_percent table">
                <div class="relative width_100_percent tr" v-for="(info, idx) in dataList" :key="info.id">
                    <div class="mt_10 relative width_100_percent order_detail_panel">
                        <div class="relative width_100_percent flex flex_wrap align_center space_between font_weight">
                            <div>下单时间:{{ info.ctime }}&emsp;订单号:{{ info.order_no }}</div>
                            <div class="">共{{ info.num }}件商品，合计：{{ info.money | cny }}（含运费：{{ info.deliveryMoney | cny }}）</div>
                        </div>
                        <div class="mt_10 relative width_100_percent flex flex_wrap align_center">
                            <Tag type="dot" :color="{ 0: 'purple', 1: 'cyan', 2: 'volcano', 3: 'success', 4: 'warning', 5: 'error' }[info.status]">{{ getOrderStatus(info.status) }}</Tag>
                            <div class="ml_15">收货人：{{ info.userName }}</div>
                            <div class="ml_15">手机号：{{ info.userTel }}</div>
                            <div class="ml_auto">
                                <Button type="primary" size="small" @click="onView(info.id)">查看详情</Button>

                                <!--<Button class="ml_20" type="warning" size="small">备注</Button>-->
                            </div>
                        </div>
                    </div>
                    <div v-show="goodInfo.isShow" class="relative flex align_center item_panel" v-for="(goodInfo, goodIdx) in info.goods" :key="info.id + '_' + goodIdx">
                        <div class="flex justify_start item width_100_percent goodMessage">
                            <div>
                                <previewImage :thumWidth="100" :thumHeight="100" :src="goodInfo.icon"></previewImage>
                            </div>
                            <div class="flex flex_1 column align_start space_evenly ml_10">
                                <div class="flex">
                                    <div class="break_all good_label">{{ goodInfo.name }}</div>
                                    <div class="ml_auto nowrap">{{ goodInfo.money | cny }}</div>
                                </div>
                                <div class="flex">
                                    <div class="break_all good_label">{{ goodInfo.data }}</div>
                                    <div class="ml_auto nowrap">x{{ goodInfo.num || 0 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="info.isMore == 1" class="relative flex align_center justify_center">
                        <Button type="success" ghost @click="onShowAll(idx)">查看更多∨</Button>
                    </div>
                    <div v-if="info.isMore == -1" class="relative flex align_center justify_center">
                        <Button type="warning" ghost @click="onHideMore(idx)">收起∧</Button>
                    </div>
                </div>
            </div>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :current="params.pageNums" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { getOrderStatus, orderStatusDef, applyStateDef } from '@/lib/order';
import { reqOrderRefundExport, reqOrderRefundList, reqApplyInfoList } from '@/lib/request/auth2';

export default {
    name: 'refundOrderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        this.orderStatusDef = orderStatusDef;
        return {
            searchs: [
                {
                    id: hm.createId(),
                    label: '时间',
                    placeholder: '请选择',
                    type: 'select',
                    bind: 'timeState',
                    list: [
                        { id: 'ctime', title: '下单时间' },
                        { id: 'payAt', title: '付款时间' },
                        { id: 'sfc_time', title: '发货时间' },
                    ],
                },
                { id: hm.createId(), label: '选择日期', placeholder: '选择日期', type: 'daterange', bind: 'timeLine', onChange: this.onChangeDate },
                {
                    id: hm.createId(),
                    label: '售后状态',
                    placeholder: '选择售后状态',
                    type: 'select',
                    bind: 'applyState',
                    list: [
                        { id: applyStateDef.ing, title: '进行中' },
                        { id: applyStateDef.ed, title: '已完成' },
                    ],
                },
                { id: hm.createId(), label: '订单号', placeholder: '输入订单号', type: 'input', bind: 'orderNo' },
                { id: hm.createId(), label: '收货人', placeholder: '输入收货人姓名', type: 'input', bind: 'userName' },
                { id: hm.createId(), label: '电话', placeholder: '输入收货人电话', type: 'input', bind: 'userPhone' },
                { id: hm.createId(), label: '售后原因', placeholder: '选择售后原因', type: 'select', bind: 'applyId', list: [] },
            ],
            dataList: [],
            orderStatus: orderStatusDef.all,
            showGoodsMaxLength: 2,
            applyModeOne: {}, //退货退款
            applyModeTwo: {}, //仅退款
        };
    },
    mounted() {
        this.getParamsInStorage();
        this.orderStatus = this.params.status || '';
        this.getApplyInfoList();
        this.getList();
    },
    methods: {
        getOrderStatus,
        onChangeDate(evt) {
            let [startTime, endTime] = evt || ['', ''];
            if (!!startTime && endTime) {
                this.params.timeLine = JSON.stringify(evt);
            } else {
                delete this.params.timeLine;
            }
        },
        //获取售后原因
        getApplyInfoList() {
            reqApplyInfoList({
                pageNums: 1,
                pageSize: 50,
            }).then(res => {
                let data = (res.data || {}).list || [];
                data.forEach(info => {
                    info.title = info.applyNote;
                });
                this.searchs[6].list = data;
            });
        },
        //获取列表数据
        getList() {
            if (this.orderStatus == '') {
                delete this.params.status;
            } else {
                this.params.status = this.orderStatus;
            }
            this.setParamsInStorage();
            // console.log(JSON.stringify(this.params, null, '\t'));
            this.showAjaxLoading();
            reqOrderRefundList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                    this.applyModeOne = res?.data?.applyModeOne || {};
                    this.applyModeTwo = res?.data?.applyModeTwo || {};
                    let dataList = this.dataList;
                    let showGoodsMaxLength = this.showGoodsMaxLength;
                    dataList.forEach(info => {
                        info.goods.forEach((info, idx) => {
                            info.isShow = idx >= showGoodsMaxLength ? false : true;
                        });
                        info.isMore = info.goods.length > showGoodsMaxLength ? 1 : 0;
                    });
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        onShowAll(listIdx) {
            this.dataList[listIdx].isMore = -1;
            this.dataList[listIdx].goods.forEach(goodInfo => {
                goodInfo.isShow = true;
            });
            this.dataList.push('');
            this.dataList.pop();
        },
        onHideMore(listIdx) {
            this.dataList[listIdx].isMore = 1;
            let showGoodsMaxLength = this.showGoodsMaxLength;
            this.dataList[listIdx].goods.forEach((info, idx) => {
                info.isShow = idx >= showGoodsMaxLength ? false : true;
            });
            this.dataList.push('');
            this.dataList.pop();
        },

        onView(id) {
            this.goUrl('/financeOrderDetail', {
                id,
            });
        },

        //导出
        getExport() {
            if (this.orderStatus == '') {
                delete this.params.status;
            } else {
                this.params.status = this.orderStatus;
            }
            let params = window.structuredClone(this.params);
            params.xls = 1;
            reqOrderRefundExport(params);
        },

        //退款记录分析
        onRefundAnalysis() {
            this.goUrl('/refundLogAnalysis');
        },
    },
};
</script>
<style lang="less" scoped>
.table {
    .header {
        background-color: #f8f8f9;
        .item {
            padding: 8px;
            font-weight: bold;
        }
    }
    .order_detail_panel {
        padding: 12px 50px 12px 20px;
        background-color: #dcdee2;
    }
    & .item_panel ~ .item_panel {
        border-top: 2px #f8f8f9 solid;
    }
    .item_panel {
        transition: background-color 0.3s;
        & div,
        & span {
            font-size: 14px;
        }
        &:hover {
            background-color: #ebf7ff;
        }
    }

    .item {
        flex: 1;
        min-width: 100px;
        padding: 12px;
        word-break: break-all;
    }
    .btns {
        gap: 10px;
    }
    .limit_width {
        flex-shrink: 0;
        flex-grow: 0;

        &.nowState {
            width: 150px;
            flex-basis: 150px;
        }
        &.good_single_num {
            width: 100px;
        }
    }
    .flex_1 {
        flex: 1;
    }
    .good_label {
        width: 500px;
        margin-right: 5px;
    }
}
</style>
