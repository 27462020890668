<template id="previewImage">
    <div class="preview_image">
        <div class="preview_image_icon" :style="{ width: thumWidth + 'px', height: thumHeight + 'px' }">
            <template v-if="!!src">
                <img class="object_fit_cover img" :src="src" />
                <div class="icon_panel" @click="onClickedPreview">
                    <Icon :size="30" type="ios-eye" color="#ffffff" />
                </div>
            </template>
            <div v-else class="pages flex align_center justify_center">{{ et }}</div>
        </div>

        <Modal v-model="previewModel" title="图片预览" @on-ok="ok" @on-cancel="cancel" :width="vw ? vw + 40 : 840">
            <div style="overflow: auto" :style="{ width: vw + 'px', height: vh + 'px' }">
                <img :src="src" />
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
    name: 'previewImage',
    props: {
        src: null,
        et: {
            type: String,
            default: '',
        },
        thumWidth: {
            type: Number,
            default: 114,
            required: false,
        },
        thumHeight: {
            type: Number,
            default: 68,
            required: false,
        },
        vw: {
            type: Number,
            default: 800,
            required: false,
        },
        vh: {
            type: Number,
            default: 600,
            required: false,
        },
    },
    data() {
        return {
            previewModel: false,
        };
    },
    beforeMount() {
        // console.log("beforeMount previewModel=" + this.previewModel);
    },
    mounted() {
        // console.log("previewModel=" + this.previewModel);
    },
    methods: {
        ok() {
            this.previewModel = false;
        },
        cancel() {
            this.ok();
        },
        onClickedPreview() {
            this.previewModel = true;
        },
    },
};
</script>
<style type="text/css">
.preview_image .preview_image_icon {
    position: relative;
    width: 114px;
    height: 68px;
    cursor: pointer;
    text-align: center;
    line-height: 68px;
}
.preview_image .preview_image_icon .img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
}
.preview_image .preview_image_icon .icon_panel {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
}
.preview_image .preview_image_icon:hover .icon_panel {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}
</style>
