import { reqOrderSfcMod } from '@/lib/request/auth2';
import { isFunction } from '@/lib/util';

export const applyStateDef = {
    //售后状态 1 进行中 2 已完成
    all: '', //全部
    ing: '1', //1
    ed: '2', //2
    // off: '3', //售后关闭
};

//获取售后状态文本
export const getApplyState = saleStatus => {
    return (
        {
            [applyStateDef.all]: '全部',
            [applyStateDef.ing]: '买家申请退款，等待卖家处理',
            [applyStateDef.ed]: '售后完成',
            [applyStateDef.off]: '售后关闭',
        }[saleStatus] || saleStatus
    );
};

export const orderStatusDef = {
    //订单状态 0待付款 1待发货 2已发货 3已完成 4售后 5关闭",
    all: '', //全部
    payIng: '0', //待付款
    sendIng: '1', //待发货
    sendEd: '2', //已发货
    orderEd: '3', //已完成
    afterSale: '4', //退款售后
    orderClose: '5', // 已关闭
};

//获取订单状态文本
export const getOrderStatus = _orderStatus => {
    if (!!!_orderStatus) {
        return '';
    }

    let orderStatus = _orderStatus + '';
    let res = '';
    switch (orderStatus) {
        case orderStatusDef.payIng:
            res = '等待买家付款';
            break;
        case orderStatusDef.sendIng:
            res = '等待卖家发货';
            break;
        case orderStatusDef.sendEd:
            res = '已发货';
            break;
        case orderStatusDef.afterSale:
            res = '退款售后';
            break;
        case orderStatusDef.orderEd:
            res = '交易成功';
            break;
        case orderStatusDef.orderClose:
            res = '交易关闭';
            break;
        default:
            res = orderStatus;
            break;
    }
    return res;
};

//获取发货状态文本
export const getSfcStatus = status => {
    let objSfcStatus = {
        0: '发货中',
        1: '已发货',
        2: '已签收',
    };
    return objSfcStatus[status] || status || '-';
};

//查询物流商列表
export const getSfcModList = cb => {
    reqOrderSfcMod()
        .then(res => {
            let list = res?.data?.list || [];
            isFunction(cb) && cb(list);
        })
        .catch(() => {
            isFunction(cb) && cb([]);
        });
};

// 售后方式
export const applyModeDef = {
    returnAll: { value: '1', title: '退货退款' },
    onlyReturnMoney: { value: '2', title: '仅退款' },
};

//获取售后方式
export const getApplyMode = applyMode => {
    let str = applyMode;
    for (let key in applyModeDef) {
        if (applyMode == applyModeDef[key].value) {
            str = applyModeDef[key].title;
            break;
        }
    }
    return str;
};

//同意/拒绝退款状态
export const applyRefundStateDef = {
    agree: '1',
    refuse: '2',
};
